export const isDevelopment = process.env.NODE_ENV === "development";
export const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview";
export const isStaging = process.env.NEXT_PUBLIC_VERCEL_ENV === "staging";
export const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
export const isCI = Boolean(process.env.CI);

export const getEnvironment = () => {
  if (isProduction) return "production";
  if (isStaging) return "staging";
  if (isPreview) return "preview";
  if (isDevelopment) return "development";
  if (isCI) return "ci";
  return undefined;
};

export const SERVICE_NAME = "autocode";
// Backend service name for API tracing
export const API_SERVICE_NAME = `${SERVICE_NAME}-api`;

export const ALLOWED_TRACING_ORIGINS: (RegExp | string)[] = [
  /https:\/\/(.*\.)?zapier(-staging|-deployment)?\.com/,
];

export const ZAPIER_ORIGIN = (() => {
  if (process.env.NEXT_PUBLIC_ZAPIER_ORIGIN) {
    return process.env.NEXT_PUBLIC_ZAPIER_ORIGIN;
  }

  if (isDevelopment) return "https://zapier-staging.com";
  if (isPreview || isStaging) return "https://zapier-staging.com";
  return "https://zapier.com";
})();

export const ZAPIER_ADMIN_ORIGIN = (() => {
  if (process.env.NEXT_PUBLIC_ZAPIER_ADMIN_ORIGIN) {
    return process.env.NEXT_PUBLIC_ZAPIER_ADMIN_ORIGIN;
  }
  if (isDevelopment) return "https://admin.internal.zapier-staging.com";
  if (isPreview || isStaging)
    return "https://admin.internal.zapier-staging.com";
  return "https://admin.zapier.com";
})();

export const AUTOCODE_ORIGIN = (() => {
  if (process.env.NEXT_PUBLIC_AUTOCODE_ORIGIN) {
    return process.env.NEXT_PUBLIC_AUTOCODE_ORIGIN;
  }

  if (isDevelopment) return `http://localhost:${process.env.PORT}`;
  if (isPreview || isStaging) return "https://autocode.zapier-staging.com";
  return "https://autocode.zapier.com";
})();

export const ACE_BACKEND_ORIGIN = (() => {
  if (process.env.ACE_BACKEND_ORIGIN) {
    return process.env.ACE_BACKEND_ORIGIN;
  }
  // When running the native backend, it only answers on ipv4, so we can't use
  // localhost here.
  if (isDevelopment) return "http://127.0.0.1:9437";
  if (isPreview || isStaging)
    return "https://appcustomextensions.zapier-staging.com";
  return "https://appcustomextensions.zapier.com";
})();

export const RELAY_ORIGIN = (() => {
  if (process.env.RELAY_ORIGIN) {
    return process.env.RELAY_ORIGIN;
  }
  if (isDevelopment) return "https://relay.zapier-staging.com";
  if (isPreview || isStaging) return "https://relay.zapier-staging.com";
  return "https://relay.zapier.com";
})();

export const AUTHORIZE_URL = isPreview
  ? `https://zapier-oauth-relay.vercel.zapier-deployment.com/api/authorize`
  : `${ZAPIER_ORIGIN}/oauth/authorize/`;

export const TOKEN_URL = isPreview
  ? `https://zapier-oauth-relay.vercel.zapier-deployment.com/api/token`
  : `${ZAPIER_ORIGIN}/oauth/token/`;

export const USE_OAUTH = (() => {
  if (process.env.NEXT_PUBLIC_USE_OAUTH) {
    return process.env.NEXT_PUBLIC_USE_OAUTH === "true";
  }
  if (isDevelopment) {
    return true;
  }
  return false;
})();

export const IS_GENERALLY_AVAILABLE = (() => {
  if (process.env.NEXT_PUBLIC_GENERAL_AVAILABILITY) {
    return process.env.NEXT_PUBLIC_GENERAL_AVAILABILITY === "true";
  }
  if (isDevelopment) {
    return false;
  }
  return true;
})();

export const ACE_API_BASE_URL = (() => {
  if (process.env.NEXT_PUBLIC_ACE_API_BASE_URL) {
    return process.env.NEXT_PUBLIC_ACE_API_BASE_URL;
  }
  return "/api/ace/v1";
})();

export const USE_ZAPIER_PROXY =
  !ZAPIER_ORIGIN || ACE_API_BASE_URL.startsWith(ZAPIER_ORIGIN);

export const V4_SESSION = `${ZAPIER_ORIGIN}/api/v4/session/`;
export const V4_ACCOUNTS = `${ZAPIER_ORIGIN}/api/v4/accounts/`;
export const V4_AUTHENTICATIONS = `${ZAPIER_ORIGIN}/api/v4/authentications/`;
export const V4_INVOKE = `${ZAPIER_ORIGIN}/api/v4/implementations/invoke/`;
export const V4_INVOKE_SYNC = `${ZAPIER_ORIGIN}/api/v4/implementations/invoke-sync/`;
export const V4_CHOICES = `${ZAPIER_ORIGIN}/api/v4/implementations/choices/`;
export const V4_IMPLEMENTATIONS = `${ZAPIER_ORIGIN}/api/v4/implementations/`;

// Tracking
export const EVENTS_ORIGIN = (() => {
  if (process.env.EVENTS_ORIGIN) {
    return process.env.EVENTS_ORIGIN;
  }
  if (isProduction) return "https://events.zapier.com";
  return "https://events.zapier-staging.com";
})();
export const EVENTS_EMIT_ENDPOINT = `${EVENTS_ORIGIN}/api/v1/emit`;

export const PREDICTION_SOURCE_API_SPEC =
  "appcustomextensions.api_spec_suggestion.v1";
export const AiTrackingPredictionOutputEventName =
  "ai.tracking.PredictionOutputEvent";
export const AIMessageUserFeedbackEventName =
  "platform.app_extensions.tracking.AIMessageUserFeedbackEvent";

export const CODE_RUNNER_URL = (() => {
  if (process.env.CODE_RUNNER_URL) {
    return process.env.CODE_RUNNER_URL;
  }
  if (isProduction) {
    return "https://coderunner.zapier.com/run_code";
  }
  // Default to staging.
  return "https://coderunner.zapier-staging.com/run_code";
})();

export const CODE_RUNNER_SERVICE_KEY = process.env.CODE_RUNNER_SERVICE_KEY;
export const CODE_RUNNER_TENANT_ID = process.env.CODE_RUNNER_TENANT_ID;

// Number of times to retry running action code.
export const ACTION_RUN_MAX_RETRIES = (() => {
  const defaultMaxRetries = 4;
  if (process.env.ACTION_RUN_MAX_RETRIES) {
    return (
      parseInt(process.env.ACTION_RUN_MAX_RETRIES, 10) || defaultMaxRetries
    );
  }
  return defaultMaxRetries;
})();

// Maximum amount of time to spend on retries.
export const ACTION_RUN_MAX_RETRY_TIME = (() => {
  const defaultMaxRetryTime = 90_000;
  if (process.env.ACTION_RUN_MAX_RETRY_TIME) {
    return (
      parseInt(process.env.ACTION_RUN_MAX_RETRY_TIME, 10) || defaultMaxRetryTime
    );
  }
  return defaultMaxRetryTime;
})();

export const LOG_LEVEL = (() => {
  // Allow the server level to be indepenent from the browser level.
  if (process.env.LOG_LEVEL) {
    return process.env.LOG_LEVEL;
  }
  if (process.env.NEXT_PUBLIC_LOG_LEVEL) {
    return process.env.NEXT_PUBLIC_LOG_LEVEL;
  }
  if (isProduction) {
    return "debug";
  }
  return "trace";
})();

// Databricks default non-custom config
export const DATABRICKS_ORIGIN =
  process.env.DATABRICKS_ORIGIN ||
  "https://dbc-f098c79d-13cc.cloud.databricks.com";
export const DATABRICKS_API_KEY = process.env.DATABRICKS_API_KEY;
export const DATABRICKS_WAREHOUSE_ID =
  process.env.DATABRICKS_WAREHOUSE_ID || "52e4ce1eecfbb3f9";

// Databricks default for customer config
export const DATABRICKS_API_KEY_CC = process.env.DATABRICKS_API_KEY_CC;
export const DATABRICKS_WAREHOUSE_ID_CC =
  process.env.DATABRICKS_WAREHOUSE_ID_CC || "6968ca8c394ed3a1";
export const DATABRICKS_ORIGIN_CC =
  process.env.DATABRICKS_ORIGIN_CC ||
  "https://dbc-f784d0eb-ff30.cloud.databricks.com";

export const ZAPIER_ACCOUNT_ID = "2074697";

export const MULTILINE_LOGGING = process.env.MULTILINE_LOGGING === "true";
